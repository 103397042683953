import { render, staticRenderFns } from "./ECommerceProductDetailsRelatedProducts.vue?vue&type=template&id=7c788f91"
import script from "./ECommerceProductDetailsRelatedProducts.vue?vue&type=script&lang=js"
export * from "./ECommerceProductDetailsRelatedProducts.vue?vue&type=script&lang=js"
import style0 from "./ECommerceProductDetailsRelatedProducts.vue?vue&type=style&index=0&id=7c788f91&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports